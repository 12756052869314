<template>
  <div>
    <!-- 媒体通道 -->
    <index2 v-if="$route.params.miceLink==='2d94b75de2dd4b0cb5163507473f560a'" index2 />
    <index1 v-else />
  </div>
</template>

<script>
import index1 from './index1'
import index2 from './index2'
export default {
  components: {
    index1,
    index2
  }
}
</script>

<style>

</style>