<template>
  <div class="float-menu fl-right" :style="{ left: left, top: top }" @click="goToIndex">
    <div class="wrap" :style="{ background: $store.state.themeInfo.themeColor }">
      <van-icon name="wap-home-o" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'floatMenu',
  props: {
    innerPage: {
      type: Boolean,
      default: false
    },
    conferencePage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      left: 'calc(100% - 58px)',
      top: '40%'
    }
  },
  mounted() {
    this.initFloatMenu()
  },
  methods: {
    goToIndex() {
      if (this.innerPage) {
        this.$emit('goHome')
      } else if (this.conferencePage) {
         this.$router.go(-1)
      } else {
        this.$router.push('/index/' + this.$store.state.miceInfo.miceLink || this.$route.params.miceLink)
      }
    },
    initFloatMenu() {
      this.$nextTick(() => {
        const menu = document.querySelector('.float-menu')
        const mh = parseFloat(getComputedStyle(menu).height)
        const mw = parseFloat(getComputedStyle(menu).width)
        const screenWidth = parseFloat(getComputedStyle(document.documentElement).width)
        const screenHeight = parseFloat(window.screen.availHeight)
        this.left = `calc(100% - ${mw}px)`
        menu.addEventListener('touchmove', e => {
          e.preventDefault()
          menu.style.left = e.touches[0].clientX - mw / 2 + 'px'
          menu.style.top = e.touches[0].clientY - mh / 2 + 'px'
          menu.style.transition = '0s'
        }, { passive: false })
        menu.addEventListener('touchstart', () => {
          menu.classList.remove('fl-right')
          menu.classList.remove('fl-left')
        }, { passive: false })
        menu.addEventListener('touchend', () => {
          menu.style.transition = '.3s'
          if (screenWidth - parseFloat(getComputedStyle(menu).left) - mw / 2 > screenWidth / 2) {
            menu.style.left = '0px'
            menu.classList.add('fl-left')
          } else {
            menu.style.left = `calc(100% - ${mw}px)`
            menu.classList.add('fl-right')
          }
          if (parseFloat(getComputedStyle(menu).top) < 0 || parseFloat(getComputedStyle(menu).top) + mh > screenHeight) {
            menu.style.top = parseFloat(getComputedStyle(menu).top) < 0 ? '0px' : screenHeight - mh + 'px'
          }
        }, { passive: false })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.float-menu{
  padding: 8px;
  position: fixed;
  background: rgba(255, 255, 255, .5);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, .2);
  border-radius: 50%;
  transition: .3s;
  z-index: 10;
  backdrop-filter: blur(5px);
  .wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 32px;
    width: 32px;
    font-size: 23px;
    border-radius: 50%;
  }
}
.fl-left{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.fl-right{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>