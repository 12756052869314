<template>
  <div class="banner-container swiper-container" ref="container">
    <!-- <img class="bg" src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202306061908363536815.png" alt="">
    <div class="active-logo" :style="{ marginTop: isArrangePage ? '0' : '-45px' }"></div>
    -->
    <div class="swiper-slide banner-item">
      <img
        class="bg"
        src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202409041433453738660.jpg"
        alt=""
      />
      <canvas id="canvas" ref="canvas"></canvas>
      <img
        class="txt"
        src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202408261146531749259.png"
        alt=""
      />
    </div>
    <!-- <div class="swiper-wrapper">
      <div class="swiper-slide banner-item">
        <img class="bg" src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202306061908363536815.png" alt="">
        <canvas id="canvas" ref="canvas"></canvas>
      </div>
      <div class="swiper-slide banner-item">
        <img class="bg" src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202208301444484419706.jpg?imageView2/2/w/1500" alt="">
      </div>
    </div> -->
  </div>
</template>

<script>
import Swiper from "swiper/js/swiper.js";
import "swiper/css/swiper.css";
export default {
  name: "banner",
  props: {
    active: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    if (this.active) {
      this.$nextTick(() => {
        this.initCanvas();
        this.initSwiper();
      });
    }
  },
  computed: {
    isArrangePage() {
      return this.$route.path.includes("arrangeDay");
    },
  },
  methods: {
    initCanvas() {
      const canvas = this.$refs.canvas;
      canvas.height = this.$refs.container.clientHeight;
      canvas.width = this.$refs.container.clientWidth;
      const ctx = canvas.getContext("2d");
      const starList = [];
      const rainList = [];
      function star() {
        this.x = Math.floor(Math.random() * canvas.width);
        this.y = Math.floor(Math.random() * canvas.height);
        this.speed = (Math.random() + 0.5) / 50;
        this.opacity = Math.random() < 0.5 ? 0 : 0.5;
        this.dir = true;
      }
      star.prototype.render = function () {
        ctx.save();
        ctx.beginPath();
        ctx.fillStyle = `rgba(255, 255, 255, ${this.opacity >= 0 ? this.opacity : 0})`;
        ctx.fillText(".", this.x, this.y);
        ctx.fill();
        ctx.restore();
      };
      star.prototype.updata = function () {
        this.opacity = this.dir ? this.opacity + this.speed : this.opacity - this.speed;
        if (this.opacity >= 1) {
          this.dir = false;
        }
        if (this.opacity <= -2) {
          this.reset();
        }
      };
      star.prototype.reset = function () {
        this.x = Math.floor(Math.random() * canvas.width);
        this.y = Math.floor(Math.random() * canvas.height);
        this.speed = (Math.random() + 0.5) / 50;
        this.dir = true;
        this.opacity = 0;
      };

      function rain() {
        this.length = Math.floor(Math.random() * 10 + 10);
        this.x1 = canvas.width / 2;
        this.y1 = canvas.height / 2;
        this.speed = Math.random() * 360;
        this.x2 = this.x1 + this.length * Math.sin(this.speed * (Math.PI / 180));
        this.y2 = this.y1 + this.length * Math.cos(this.speed * (Math.PI / 180));
        this.opacity = -100;
      }
      rain.prototype.render = function () {
        ctx.save();
        ctx.beginPath();
        ctx.strokeStyle = `rgba(255,255,255,${this.opacity <= 0 ? 0 : this.opacity})`;
        ctx.moveTo(this.x1, this.y1);
        ctx.lineTo(this.x2, this.y2);
        ctx.stroke();
        ctx.restore();
      };
      rain.prototype.updata = function () {
        this.x1 += Math.sin(this.speed * (Math.PI / 180)) * 4;
        this.y1 += Math.cos(this.speed * (Math.PI / 180)) * 4;
        this.x2 += Math.sin(this.speed * (Math.PI / 180)) * 4;
        this.y2 += Math.cos(this.speed * (Math.PI / 180)) * 4;
        this.opacity += 0.008;
        if (
          this.x1 > canvas.width ||
          this.x1 < 0 ||
          this.y1 > canvas.height ||
          this.y1 < 0
        ) {
          this.reset();
        }
      };

      rain.prototype.reset = function () {
        this.length = Math.floor(Math.random() * 10 + 10);
        this.x1 = canvas.width / 2;
        this.y1 = canvas.height / 2;
        this.speed = Math.random() * 360;
        this.x2 = this.x1 + this.length * Math.sin(this.speed * (Math.PI / 180));
        this.y2 = this.y1 + this.length * Math.cos(this.speed * (Math.PI / 180));
        this.opacity = 0;
      };
      function draw() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        starList.forEach((item) => {
          item.render();
          item.updata();
        });
        rainList.forEach((item) => {
          item.render();
          item.updata();
        });
        requestAnimationFrame(draw);
      }
      const requestAnimationFrame =
        window.requestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        function (callback) {
          setTimeout(callback, 1000 / 60);
        };

      for (let i = 0; i < 70; i++) {
        starList.push(new star());
      }

      for (var i = 0; i < 40; i++) {
        rainList.push(new rain());
      }

      draw();
    },
    initSwiper() {
      this.swiper = new Swiper(".banner-container", {
        spaceBetween: 0,
        slidesPerView: 1,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        loop: true,
        speed: 600,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.banner-item {
  position: relative;
  .txt {
    width: 70%;
    position: absolute;
    left: 15%;
    top: 35%;
    z-index: 110;
    animation: txtAni 3s linear infinite;
  }
}
.banner-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.8rem;
  background: #ccc;
  .text-img {
    position: absolute;
    top: 20%;
    left: 40%;
    width: 50%;
    animation: act 10s linear infinite;
  }
  .bg {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: fill;
  }
  .logo {
    position: absolute;
    left: 5px;
    top: 5px;
    height: 37px;
  }
  .active-logo {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 2;
    img {
      animation: act 15s ease infinite;
      z-index: 2;
      height: 30px;
    }
    // img:nth-of-type(1){
    //   height: 34px;
    // }
    // img:nth-of-type(2){
    //   height: 13px;
    //   // animation-delay: 3s;
    // }
    @keyframes act {
      10% {
        transform: translate(20px, 5px);
      }
      20% {
        transform: translate(1px, 0px);
      }
      40% {
        transform: translate(0px, 10px);
      }
      60% {
        transform: translate(20px, 10px);
      }
      70% {
        transform: translate(-5px, 8px);
      }
      80% {
        transform: translate(5px, -2px);
      }
      100% {
        transform: translate(0, 0);
      }
    }
    .cloud {
      position: absolute;
      height: 50px;
      width: 200px;
      background: rgba(255, 255, 255, 0.5);
      filter: blur(40px);
      z-index: 1;
      animation: fade 5s linear infinite;
    }
    @keyframes fade {
      50% {
        transform: translate(2px, -1px);
        background: rgba(255, 255, 255, 0.8);
      }
      100% {
        transform: translate(0, 0);
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }
  #canvas {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
}

// @keyframes txtAni {
//     0% {
//         transform: scale(1);
//     }
//     25% {
//         transform: scale(1.1);
//     }
//     50% {
//         transform: scale(1);
//     }
//     75% {
//         transform: scale(0.9);
//     }
//     100% {
//       transform: scale(1);
//     }
// }

@keyframes txtAni {
  0% {
    // transform: scale(1);
    left: 10%;
  }
  25% {
    left: 15%;
  }
  50% {
    left: 20%;
  }
  75% {
    left: 15%;
  }
  100% {
    left: 10%;
  }
}
</style>
