<template>
  <div :style="{ '--themeColor': $store.state.themeInfo.themeColor }">
    <van-form @submit="onSubmit" v-show="pageOk">
  
      <div v-for="(item, index) in params" :key="index">
        <div v-if="index===0" class="des-wrapper">一、基本信息</div>
        <div v-if="index===7" class="des-wrapper">二、疫情防控信息（根据组委会疫情防控相关规定进场媒体必须持有24小时核酸检测证明）</div>
        <div v-if="index===10" class="des-wrapper">三、采访需求</div>
        
        <template v-if="index===10">
          <van-field
            v-if="liveList.length > 0"
            readonly
            clickable
            label="会场"
            required
            v-model="groupString"
            :rules="[{ required: true, message: '请选择分会场', trigger: 'onChange' }]"
            placeholder="选择分会场"
            @click="showGroup"
          >
            <template #label>
              <span>报</span>
              <span>名</span>
              <span>会</span>
              <span>场</span>
            </template>
            <template #input>
              <div class="group-wraper" v-if="selectedGroup.length > 0">
                <van-tag class="tag" type="primary" size="medium" v-for="item in selectedGroup" :key="item">{{
                  item
                }}</van-tag>
              </div>
            </template>
          </van-field>
        </template>


        <div v-if="item.dataType === 'string'">
          <!-- <p class="text-tips" v-if="item.paramsName === 'phone' && !isEdit">注: 同一手机号码只能为一名参会代表注册，请务必使用参会者本人手机号，否则将影响其会议信息查看及入场签到。</p> -->
          <van-field
            class="input-field"
            v-if="item.paramsName === 'phone'"
            v-model="form.phone"
            required
            :label="item.name"
            :disabled="isEdit"
            :placeholder="'请填写'+item.name"
            :error-message="phoneErrorMsg"
            @input="phoneErrorMsg = ''"
            maxlength="11"
            type="digit"
            :rules="[{ required: true, message: '请填写正确手机号', pattern: phoneRules, trigger: 'onChange' }]"
          >
            <!-- <template #label>
              <span>手</span>
              <span>机</span>
              <span>号</span>
            </template> -->
          </van-field>
          <van-field
            class="input-field"
            v-else-if="item.paramsName === 'name'"
            v-model="form.name"
            :required="item.isMust === 1"
            :disabled="item.canEdit === 0"
            label=""
            placeholder="请填写姓名"
            :rules="[{ required: item.isMust === 1, message: '请填写姓名', trigger: 'onChange' }]"
            maxlength="18"
          >
            <template #label>
              <span>姓</span>
              <span>名</span>
            </template>
          </van-field>
          <van-field
            class="input-field"
            v-else-if="item.paramsName === 'idCard'"
            v-model="form.idCard"
            :required="item.isMust === 1"
            :disabled="item.canEdit === 0"
            label="身份证号"
            placeholder="请填写身份证号"
            maxlength="18"
            :rules="[
              {
                required: item.isMust === 1,
                message: '请填写正确的身份证号',
                validator: idCardRules,
                trigger: 'onChange'
              }
            ]"
          >
            <template #label>
              <span>身</span>
              <span>份</span>
              <span>证</span>
              <span>号</span>
            </template>
          </van-field>
          <van-field
            class="input-field"
            v-else-if="item.paramsName === 'faceUrl'"
            label="上传头像(不超过10MB)"
            v-model="form.faceUrl"
            :disabled="item.canEdit === 0"
            :required="item.isMust === 1"
            :rules="[{ required: item.isMust === 1, message: '请上传头像' }]"
          >
            <template #input>
              <van-uploader
                v-if="!getUserMedia"
                capture="user"
                v-model="faceImg"
                :disabled="item.canEdit === 0"
                :deletable="item.canEdit !== 0"
                :max-count="1"
                :before-read="beforeUpload"
                :after-read="uploadFace"
              />
              <template v-if="getUserMedia">
                <div v-if="!photo" class="photo-box" @click="showCamera = true">
                  <van-icon name="photograph" />
                </div>
                <div class="head-img-wraper">
                  <img class="head-img" v-if="photo" :src="photo" @click="rePhoto" alt="" />
                  <van-loading class="loading" v-if="faceLoading"></van-loading>
                </div>
              </template>
            </template>
            <template #label>
              <span>上</span>
              <span>传</span>
              <span>头</span>
              <span>像</span>
              <span class="tips">(不超过10MB)</span>
            </template>
          </van-field>
          <!-- 健康码 -->
          <template v-else-if="item.paramsName === 'healthCode'">
            <van-field
              class="input-field-img"
              :label="item.name + '(不超过10MB)'"
              v-model="form[item.paramsName]"
              :disabled="item.canEdit === 0"
              :required="item.isMust === 1"
              :rules="[{ required: item.isMust === 1, message: '请上传图片' }]"
            >
              <template #label>
                <span>湖</span>
                <span>南</span>
                <span>健</span>
                <span>康</span>
                <span>码</span>
                <span class="open" @click="handleCodeShow(1)">示例</span>
                <span class="tips">(不超过10MB)</span>
                <!-- <span class="code-tip" @click="handleCodeShow(1)">示例</span> -->
              </template>
              <template #input>
                <van-uploader
                  v-model="healthCodeImg"
                  :name="item.paramsName"
                  :disabled="item.canEdit === 0"
                  :deletable="item.canEdit !== 0"
                  :max-count="1"
                  :before-read="beforeUpload"
                  :preview-options="{ closeable: true, closeIconPosition: 'top-left' }"
                  :after-read="uploadImg"
                />
                <div class="code-box">
                  <img
                    src="https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202109121045431885341.jpg"
                    alt=""
                  />
                  <div>长按扫码查看健康码</div>
                </div>
              </template>
            </van-field>
          </template>
          <template v-else-if="item.paramsName === 'healthTraffic'">
            <van-field
              class="input-field-img"
              :label="item.name + '(不超过10MB)'"
              v-model="form[item.paramsName]"
              :disabled="item.canEdit === 0"
              :required="item.isMust === 1"
              :rules="[{ required: item.isMust === 1, message: '请上传图片' }]"
            >
              <template #label>
                <span>行</span>
                <span>程</span>
                <span>码</span>
                <span class="tips">(不超过10MB)</span>
                <div class="open" @click="handleCodeShow(2)">示例</div>
              </template>
              <template #input>
                <van-uploader
                  v-model="healthTrafficImg"
                  :name="item.paramsName"
                  :disabled="item.canEdit === 0"
                  :deletable="item.canEdit !== 0"
                  :max-count="1"
                  :before-read="beforeUpload"
                  :preview-options="{ closeable: true, closeIconPosition: 'top-left' }"
                  :after-read="uploadImg"
                />
                <div class="code-box">
                  <img
                    src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202108252100453212269.jpg"
                    alt=""
                    style="width: 60px"
                  />
                  <div>长按扫码查看行程码</div>
                </div>
              </template>
            </van-field>
          </template>
          <template v-else-if="item.paramsName === 'nadImg'">
            <van-field
              class="input-field-img report"
              :label="item.name + '(不超过10MB)'"
              v-model="form[item.paramsName]"
              :disabled="item.canEdit === 0"
              :required="item.isMust === 1"
              :rules="[{ required: item.isMust === 1, message: '请上传图片' }]"
            >
              <template #label>
                <span>48</span>
                <span>小</span>
                <span>时</span>
                <span>内</span>
                <span>核</span>
                <span>酸</span>
                <span>检</span>
                <span>测</span>
                <span>报</span>
                <span>告</span>
                <span>照</span>
                <span>片</span>
                <span class="tips">(不超过10MB)</span>
              </template>
              <template #input>
                <van-uploader
                  v-model="nadImg"
                  :name="item.paramsName"
                  :disabled="item.canEdit === 0"
                  :deletable="item.canEdit !== 0"
                  :max-count="1"
                  :before-read="beforeUpload"
                  :preview-options="{ closeable: true, closeIconPosition: 'top-left' }"
                  :after-read="uploadImg"
                />
              </template>
            </van-field>
          </template>

          <van-field
            v-else-if="item.paramsName === 'email'"
            v-model="form.email"
            :required="item.isMust === 1"
            :disabled="item.canEdit === 0"
            label="邮箱"
            placeholder="请填写邮箱"
            maxlength="30"
            :rules="[
              { required: item.isMust === 1, message: '请填写正确的邮箱', pattern: emailRules, trigger: 'onChange' }
            ]"
          />
          <!-- <div v-else-if="item.paramsName === 'remarks'">
            <p class="text-tips" v-show="!isEdit">注: 如有特殊情况请填写备注</p>
            <van-field
              v-model="form.remarks"
              :required="item.isMust === 1"
              :disabled="item.canEdit === 0"
              label="备注"
              placeholder="请填写备注"
              maxlength="20"
              :rules="[{ required: item.isMust === 1, message: '请填写备注', trigger: 'onChange' }]"
            />
          </div> -->
          <van-field
            class="input-field"
            v-else-if="item.paramsName === 'position'"
            v-model="form.position"
            :required="item.isMust === 1"
            :disabled="item.canEdit === 0"
            placeholder="请填写职务"
            maxlength="30"
            :rules="[{ required: item.isMust === 1, message: '请填写职务', trigger: 'onChange' }]"
          >
            <template #label> <span>职</span><span>务</span> </template>
          </van-field>
          <van-field
            class="input-field"
            v-else-if="item.paramsName === 'company'"
            v-model="form.company"
            :required="item.isMust === 1"
            :disabled="item.canEdit === 0"
            placeholder="单位名称请填写全称"
            maxlength="30"
            :rules="[{ required: item.isMust === 1, message: '请填写单位', trigger: 'onChange' }]"
          >
            <template #label> <span>单</span><span>位</span> 
            </template>
          </van-field>
          <van-field
            v-else-if="item.paramsName === 'trafficInfo'"
            :label="trafficName[form.trafficTypeName] || item.name"
            :required="item.isMust === 1"
            :disabled="item.canEdit === 0"
            :rules="[{ required: item.isMust === 1, message: `请填写${item.name}`, trigger: 'onChange' }]"
            maxlength="20"
            v-model="form.trafficInfo"
            :placeholder="trafficName[form.trafficTypeName] || item.name"
          />

          <!-- 省市区 -->
          <van-field
            v-else-if="item.paramsName === 'address'"
            readonly
            clickable
            name="picker"
            :error="false"
            :label="item.name"
            :required="item.isMust === 1"
            :disabled="item.canEdit === 0"
            v-model="form[item.paramsName]"
            :rules="[{ required: item.isMust === 1, message: `请选择${item.name}`, trigger: 'onChange' }]"
            :placeholder="`请选择${item.name}`"
            @click="showArea = true"
          >
            <template #label>
              <span>现</span>
              <span>所</span>
              <span>在</span>
              <span>地</span>
            </template>
          </van-field>

          <van-field
            class="input-field"
            v-else
            v-model="form[item.paramsName]"
            :label="item.name"
            :required="item.isMust === 1"
            :disabled="item.canEdit === 0"
            :placeholder="`请填写${item.name}`"
            :rules="[{ required: item.isMust === 1, message: `请填写${item.name}`, trigger: 'blur' }]"
            maxlength="20"
          />
        </div>
        <div v-if="item.dataType === 'radio'">
          <van-field
            v-if="item.paramsName === 'trafficTypeName'"
            label="交通类型"
            :required="item.isMust === 1"
            :disabled="item.canEdit === 0"
            :rules="[{ required: item.isMust === 1, message: '请选择交通类型', trigger: 'onChange' }]"
          >
            <template #input>
              <van-radio-group :disabled="item.canEdit === 0" v-model="form.trafficTypeName" direction="horizontal">
                <van-radio v-for="child in item.childValue" :key="child" :name="child" class="check-style">{{
                  child
                }}</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            v-else-if="item.paramsName === 'sex'"
            label="性别"
            :required="item.isMust === 1"
            :disabled="item.canEdit === 0"
          >
            <template #label>
              <span>性</span>
              <span>别</span>
            </template>
            <template #input>
              <van-radio-group :disabled="item.canEdit === 0" v-model="form.sex" direction="horizontal">
                <van-radio :name="1">男</van-radio>
                <van-radio :name="0">女</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            v-else
            :required="item.isMust === 1"
            :disabled="item.canEdit === 0"
            :label="item.name"
            :rules="[{ required: item.isMust === 1, message: '请选择' + item.name, trigger: 'blur' }]"
          >
            <template #input>
              <van-radio-group :disabled="item.canEdit === 0" v-model="form[item.paramsName]" direction="horizontal">
                <van-radio v-for="(child, index) in item.childValue" :key="index" :name="child" class="check-style">
                  {{ child }}
                </van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <van-field
          v-if="item.dataType === 'checkbox'"
          :required="item.isMust === 1"
          :disabled="item.canEdit === 0"
          :label="item.name"
          :rules="[{ required: item.isMust === 1, message: '请选择' + item.name, trigger: 'blur' }]"
        >
          <template #input>
            <van-checkbox-group :disabled="item.canEdit === 0" v-model="form[item.paramsName]" direction="horizontal">
              <van-checkbox
                v-for="(child, index) in item.childValue"
                :key="index"
                :name="child"
                shape="square"
                class="check-style"
              >
                {{ child }}
              </van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
        <van-field
          v-if="item.dataType === 'datePicker' || item.dataType === 'timePicker' || item.dataType === 'dateTime'"
          :required="item.isMust === 1"
          :disabled="item.canEdit === 0"
          :label="item.name"
          :rules="[{ required: item.isMust === 1, message: '请选择' + item.name, trigger: 'blur' }]"
          readonly
          clickable
          :placeholder="item.name"
          v-model="form[item.paramsName]"
          @click="pickingDate(item, item.dataType, item.canEdit)"
        />
      </div>

   
      <van-action-sheet v-model="groupShow" cancel-text="确定" v-if="liveList.length > 0">
        <van-cell-group v-if="$store.state.miceInfo.limitGroup && !$route.query.type">
          <van-cell
            v-for="(item, index) in liveList"
            :key="index"
            :title="item.name"
            :label="item.subname"
            @click="groupSelect(index, item)"
            :clickable="!item.disabled"
            :title-style="{ color: item.disabled ? '#cecece' : '', tranistion: '.3s' }"
            :label-class="{ cellMask: item.disabled }"
          >
            <template #label>
              <div>{{item.subname}}</div>
              <div>{{item.address}}</div>
            </template>
            <template #right-icon>
              <van-checkbox v-model="item.checked" :disabled="item.disabled" ref="groupCheckBox"></van-checkbox>
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group v-else>
          <van-cell
            v-for="(item, index) in liveList"
            :key="index"
            :title="item.name"
            :label="item.subname"
            @click="groupSelect(index, item)"
            :title-style="{ color: item.disabled ? '' : '', tranistion: '.3s' }"
          >
            <template #label>
              <div>{{item.subname}}</div>
              <div>{{item.address}}</div>
            </template>
            <template #right-icon>
              <van-checkbox v-model="item.checked" ref="groupCheckBox"></van-checkbox>
            </template>
          </van-cell>
        </van-cell-group>
      </van-action-sheet>

      <van-action-sheet
        v-model="dateShow"
        :title="date.type === 1 ? '选择日期' : date.type === 2 ? '选择时间' : '选择日期时间'"
      >
        <van-datetime-picker
          v-if="date.type === 1"
          :min-date="date.minDate"
          v-model="date.currentDate"
          :formatter="dateFormatter"
          :columns-order="['year', 'month', 'day', 'hour', 'minute']"
          @confirm="onConfirm"
          @cancel="dateShow = false"
          type="date"
        />
        <van-datetime-picker
          v-if="date.type === 2"
          @confirm="onConfirm"
          :columns-order="['hour', 'second', 'minute']"
          :formatter="timeFormatter"
          type="time"
          @cancel="dateShow = false"
        />
        <van-datetime-picker
          v-if="date.type === 3"
          type="datetime"
          v-model="date.currentDate"
          :formatter="dateFormatter"
          :columns-order="['year', 'month', 'day', 'hour', 'minute']"
          :min-date="date.minDate"
          @confirm="onConfirm"
          @cancel="dateShow = false"
        />
      </van-action-sheet>

      <van-field
        v-if="!isEdit"
        v-model="form.verifyCode"
        center
        required
        :rules="[{ required: true, message: '请输入验证码', trigger: 'onChange' }]"
        clearable
        label="短信验证码"
        placeholder="请输入短信验证码"
        :maxlength="6"
      >
        <template #button>
          <van-button
            size="small"
            :style="{ background: $store.state.themeInfo.themeColor, borderRadius: '5px', color: '#fff' }"
            @click="sendCode"
            :disabled="waitCode"
            native-type="button"
          >
            {{ sendCodeText }}
          </van-button>
        </template>
        <template #label> <span>短</span><span>信</span><span>验</span><span>证</span><span>码</span> </template>
      </van-field>
      <div class="btn-wrap">
        <span v-if="!isEdit" :style="{ color: $store.state.themeInfo.themeColor }" @click="handleTipShow"
          >报名须知</span
        >
        <!-- <van-button
          size="small"
          :style="{ background: $store.state.themeInfo.themeColor,borderRadius: '5px', color: '#fff' }">
          报名须知
        </van-button> -->
      </div>
      <div class="save-tip" v-if="isEdit">每人每天有1次修改信息机会，请仔细确认，审核后将无法修改。</div>
      <div class="center-vertical" style="padding: 15px" v-if="!auditStatus">
        <van-button
          :disabled="loading"
          :loading="loading"
          round
          block
          type="info"
          :style="{ background: $store.state.themeInfo.themeColor, border: 'none' }"
          >{{ isEdit ? "提交修改信息" : "提交报名" }}</van-button
        >
      </div>
    </van-form>
    <transition name="slide">
      <div class="photo-wraper" v-if="showCamera">
        <Camera @getFile="getImg" @goBack="goBack" @nocamera="nocamera" />
      </div>
    </transition>
    <!-- 确认提交弹窗 -->
    <van-dialog
      v-model="submitPopShow"
      title=""
      :showCancelButton="true"
      cancelButtonText="取消"
      confirmButtonText="确认，提交"
      width="282"
      @confirm="handleConfirm"
      @cancel="handleCancle"
    >
      <div class="submit-tip">
        <!-- 请确认您的手机号、身份证号与
        <template v-if="applyInfo && !applyInfo.healthCodeState">湖南健康码</template>
        <template v-if="applyInfo && !applyInfo.healthCodeState && !applyInfo.healthTrafficState">、</template>
        <template v-if="applyInfo && !applyInfo.healthTrafficState">行程码</template>信息一致，如不一致将影响报名审核结果。 -->
        请确认您的手机号、身份证号信息一致，如不一致将影响报名审核结果。
      </div>
    </van-dialog>
    <!-- 省市区选择 -->
    <van-popup v-model="showArea" position="bottom">
      <van-area :area-list="areaList" @confirm="onAreaConfirm" @cancel="showArea = false" />
    </van-popup>

    <!-- 健康码示例 -->
    <van-overlay :show="showOpen" @click="showOpen = false">
      <div class="wrapper">
        <div class="container">
          <img v-if="openState === 1" src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202110231105443937465.jpg" alt="">
          <img v-else src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202110231106021046564.jpg" alt="">
          <div class="btn">
            <van-button color="#102E9E" type="primary" size="small">我知道了</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import Camera from "../camera/index.vue";
import myLib from "../camera/index.js";
import { areaList } from "@vant/area-data";
export default {
  name: "signForm",
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: { Camera },
  data() {
    return {
      sendCodeText: "发送验证码",
      pageOk: false,
      phoneErrorMsg: "",
      loading: false,
      waitCode: false,
      interval: null, // 定时器
      themeOk: false,
      dateShow: false,
      pickedItem: null, // 选中的日期框
      faceImg: [], // 头像
      healthCodeImg: [], // 健康码
      healthTrafficImg: [], // 行程码
      nadImg: [], // 核酸
      phoneRules:
        /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
      emailRules: /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/,
      /*********/
      form: {
        name: "",
        phone: "",
        verifyCode: "",
        idCard: "",
        sex: 1,
        faceUrl: "",
        company: "",
        position: "",
        email: "",
        remarks: "",
        trafficTypeName: "",
        trafficInfo: "",
        departureTime: "",
        arrivalTime: "",
        sharePhone: "", // 分享者手机号
        dynamicFormA: null,
        dynamicFormB: null,
        dynamicFormC: null,
        dynamicFormD: null,
        dynamicFormE: null,
        dynamicFormF: null,
        dynamicFormG: null,
        dynamicFormH: null
      },
      params: [], // 注册配置信息
      selfParams: [], // 自定义字段
      allParams: {},
      date: {
        currentDate: new Date(),
        minDate: new Date(1950, 0, 1),
        type: 1
      },
      liveList: [], // 分会场列表
      groupShow: false, // 分会场选择弹窗
      trafficName: {}, // 交通信息
      group: [], // 分享链接会场信息
      showCamera: false,
      photo: "",
      faceLoading: false,
      getUserMedia: false,
      shareType: "",
      submitPopShow: false,
      applyInfo: null, //第一次返回的报名信息
      sendLock: false, // 第二次提交锁
      areaList: areaList,
      showArea: false,
      showOpen: false,
      openState: 1
    };
  },
  computed: {
    selectedGroup() {
      let group = [];
      this.liveList.forEach(item => {
        if (item.checked) {
          group.push(item.name);
        }
      });
      // return group.join(',')
      return group;
    },
    groupString() {
      console.log(this.selectedGroup);
      return this.selectedGroup.join(",");
    },
    miceLink() {
      return this.$store.state.miceInfo.miceLink;
    },
    signUpApi() {
      return this.isEdit ? this.$baseURL.index.signUpModify() : this.$baseURL.index.signUp();
    },
    auditStatus() {
      return this.$store.state.miceInfo.isAudit === 1 && this.data.auditStatus && this.data.auditStatus === 1;
    }
  },
  mounted() {
    console.log(this.$route.query)
    if (this.$route.params.sharer) {
      const str = this.$route.params.sharer.split("&")[0];
      const paramsArr = str.split("ANDLINK");
      this.form.sharePhone = this.phoneRules.test(this.$utils.decodePhone(paramsArr[0]))
        ? this.$utils.decodePhone(paramsArr[0])
        : "";
      this.group = paramsArr[1] ? JSON.parse(this.$utils.decodeGroup(paramsArr[1])) : [];
      this.shareType = paramsArr[2] ? paramsArr[2] : [];
      console.log(this.shareType);
    }
    const getUserMedia =
      navigator &&
      navigator.mediaDevices &&
      (navigator.mediaDevices.getUserMedia ||
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia ||
        navigator.oGetUserMedia);
    if (getUserMedia) {
      this.getUserMedia = true;
    } else {
      this.getUserMedia = false;
    }
    this.getLiveList();
    this.getSignInfo();
  },
  methods: {
    async getImg(photo) {
      this.showCamera = false;
      this.photo = photo;
      const file = myLib.dataURLtoFile(photo, "headimg.jpeg");
      const data = new FormData();
      console.log(file);
      data.append("file", file);
      data.append("isFace", 1);
      this.faceLoading = true;
      let result = await this.$http.post(this.$baseURL.index.uploadImg(), data, 1);
      this.faceLoading = false;
      if (result.code === 200) {
        this.form.faceUrl = result.data;
      } else {
        this.photo = "";
        Dialog.alert({
          title: "提示",
          message: result.message
        }).then(() => {
          // on close
        });
      }
    },
    handleCodeShow(type) {
      this.showOpen = true  
      this.openState = type
    },
    rePhoto() {
      // this.photo = ''
      this.showCamera = true;
    },
    goBack() {
      this.showCamera = false;
    },
    nocamera() {
      this.showCamera = false;
      this.getUserMedia = false;
    },
    beforeUpload(file) {
      if (file.type !== "image/jpeg" && file.type !== "image/jpg" && file.type !== "image/png") {
        Toast("文件类型不支持");
        return false;
      }
      const lt2M = file.size / 1024 / 1024 < 10;
      if (!lt2M) {
        Toast("文件大小不能超过10MB");
        return false;
      }
      return true;
    },
    async uploadFace(file) {
      console.log(file);
      file.status = "uploading";
      file.message = "上传中...";
      const data = new FormData();
      data.append("file", file.file);
      data.append("isFace", 1);
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0
      });
      let result = await this.$http.post(this.$baseURL.index.uploadImg(), data, 1);
      if (result.code === 200) {
        this.form.faceUrl = result.data;
        file.status = "success";
        file.message = "上传成功";
        file.url = result.data;
        Toast.clear();
      } else {
        this.faceImg = [];
        Dialog.alert({
          title: "提示",
          message: result.message
        }).then(() => {
          // on close
        });
      }
    },
    async uploadImg(file, detail) {
      console.log(detail);
      file.status = "uploading";
      file.message = "上传中...";
      const data = new FormData();
      data.append("file", file.file);
      data.append("isFace", 0);
      this.uploadLoading = true;
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0
      });
      let result = await this.$http.post(this.$baseURL.index.uploadImg(), data, 1);
      this.uploadLoading = false;
      if (result.code === 200) {
        this.form[detail.name] = result.data;
        file.status = "success";
        file.message = "上传成功";
        file.url = result.data;
        Toast.clear();
      } else {
        this.faceImg = [];
        Dialog.alert({
          title: "提示",
          message: result.message
        }).then(() => {
          // on close
        });
      }
    },
    async onSubmit() {
      this.loading = true;
      // const hasImg = this.params.findIndex(item => { return item.paramsName === 'faceUrl' })
      // if (hasImg !== -1 && this.faceImg.length > 0 && this.faceImg[0].file) {
      //   const isImg = this.faceImg[0].file.type === 'image/jpeg' || this.faceImg[0].file.type === 'image/png' || this.faceImg[0].file.type === 'image/jpg'
      //   if (!isImg) {
      //     this.loading = false
      //     Toast('文件类型不支持')
      //     return
      //   }
      //   const lt2M = this.faceImg[0].file.size / 1024 / 1024 < 5
      //   if (!lt2M) {
      //     Toast('文件大小不能超过3Mb')
      //     this.loading = false
      //     return
      //   }
      //   const data = new FormData()
      //   data.append('file', this.faceImg[0].file)
      //   data.append('isFace', 1)
      //   // 上传头像获取url后再进行注册
      //   let result = await this.$http.post(this.$baseURL.index.uploadImg(), data, 1)
      //   if (result.code === 200) {
      //     this.form.faceUrl = result.data
      //   } else {
      //     Dialog.alert({
      //       title: '提示',
      //       message: result.message,
      //     }).then(() => {
      //       // on close
      //     })
      //     this.loading = false
      //     return
      //   }
      // }
      this.form.miceLink = this.miceLink;
      if (this.shareType === "normal") {
        this.form.shareType = 1;
      } else if (this.shareType === "vip") {
        this.form.shareType = 2;
      }
      const data = {};
      for (const key in this.form) {
        if (key.startsWith("dynamicForm") && Array.isArray(this.form[key])) {
          if (this.form[key].length > 0) {
            data[key] = this.form[key].join(",");
          } else {
            data[key] = "";
          }
        } else {
          data[key] = this.form[key];
        }
      }
      if (this.liveList.length > 0) {
        const arr = [];
        this.liveList.forEach(item => {
          if (item.checked) {
            arr.push({
              groupLink: item.groupLink,
              startDate: `${item.startDate} ${item.startTime}`,
              endDate: `${item.endDate} ${item.endTime}`
            });
          }
        });
        data.groupInfo = JSON.stringify(arr);
      }
      // 如果是编辑，增加id和审核状态
      if (this.isEdit) {
        data.id = this.data.id;
        data.auditStatus = this.data.auditStatus;
      } else {
        data.type = 2
      }
      data.openId = this.$store.state.userInfo.openid;
      let res = await this.$http.post(this.signUpApi, data, 1);
      this.loading = false;
      if (res.code === 200) {
        // 健康码识别有问题弹出弹窗
        if (res.data === true || this.isEdit) {
          this.$emit("registerChange", res.data);
          if (!this.isEdit) {
            console.log("调用短信");
            // 调用发送短信接口
            this.sendTemplate();
          }
        } else {
          this.submitPopShow = true;
          this.applyInfo = res.data.apply;
        }
      } else {
        Dialog.alert({
          title: "提示",
          message: res.message
        }).then(() => {
          // on close
        });
      }
      this.loading = false;
    },
    // 报名成功发送短信
    async sendTemplate() {
      const data = {
        miceLink: this.miceLink,
        openId: this.$store.state.userInfo.openid
      };
      let result = await this.$http.postParams(this.$baseURL.index.sendTemplate(), data, 1);
      if (result.data) {
        console.log(result);
      }
    },
    async getSignInfo() {
      // 获取注册配置信息
      let result = await this.$http.get(this.$baseURL.index.getSignInfo() + "?miceLink=" + this.miceLink);
      this.params = [];
      this.selfParams = [];
      result.forEach(item => {
        if ((item.dataType === "checkbox" || item.dataType === "radio") && item.paramsName !== "sex") {
          if (item.dataType === "checkbox") {
            // 编辑时初始值赋值
            this.form[item.paramsName] = this.data[item.paramsName] ? this.data[item.paramsName].split(",") : [];
          } else {
            this.form[item.paramsName] = this.data[item.paramsName] || "";
          }
          item.childValue = item.childValue.split("|");
        } else {
          this.form[item.paramsName] =
            typeof this.data[item.paramsName] === "undefined" ? "" : this.data[item.paramsName];
        }
        if (!this.isEdit) {
          item.canEdit = 1;
        } else {
          // 审核状态通过则禁止修改
          // if (this.auditStatus) {
          //   item.canEdit === 0
          // }
        }
        this.params.push(item);
      });
      const posi = this.params.findIndex(item => {
        return item.paramsName === "trafficTypeName";
      });
      if (posi !== -1) {
        const infoIndex = this.params.findIndex(item => {
          return item.paramsName === "trafficInfo";
        });
        this.params[posi].childValue.forEach((child, index) => {
          this.trafficName[child] = this.params[infoIndex].name.split("/")[index];
        });
      }
      if (this.isEdit && this.data.faceUrl) {
        this.faceImg.push({
          url: this.data.faceUrl
        });
        this.photo = this.data.faceUrl;
      }
      // 健康码
      this.data.healthCode &&
        this.healthCodeImg.push({
          url: this.data.healthCode
        });
      this.data.healthTraffic &&
        this.healthTrafficImg.push({
          url: this.data.healthTraffic
        });
      this.data.nadImg &&
        this.nadImg.push({
          url: this.data.nadImg
        });
      this.pageOk = true;
    },
    async getLiveList() {
      // 获取分会场
      let result = await this.$http.get(
        this.$baseURL.index.getGroupList() + "?miceLink=" + this.$store.state.miceInfo.miceLink,
        1
      );
      if (result.code === 200) {
        result.data.forEach(item => {
          if (item.display === 1) {
            let index = -1;
            let posi = -1;
            if (this.data.groupInfo) {
              index = JSON.parse(this.data.groupInfo).findIndex(child => {
                return child.groupLink === item.groupLink;
              });
            }
            if (!this.isEdit) {
              posi = this.group.findIndex(child => {
                return child === item.groupLink;
              });
            }
            if (item.name !== "制造新高地 云创新未来") {
              this.liveList.push({
                name: item.name,
                subname: `${item.startDate} ${item.startTime.substr(0, 5)}-${item.endTime.substr(0, 5)}`,
                groupLink: item.groupLink,
                startDate: item.startDate,
                startTime: item.startTime,
                endTime: item.endTime,
                endDate: item.endDate,
                checked: index !== -1 || posi !== -1,
                disabled: false,
                address: item.address
              });
            }
          }
        });
      } else {
        Dialog.alert({
          title: "提示",
          message: result.message
        }).then(() => {
          // on close
        });
      }
    },
    groupSelect(index, child) {
      // 判断分会场时间冲突
      // console.log(item)
      if (child.disabled) {
        return;
      }
      this.$refs.groupCheckBox[index].toggle();
      this.liveList[index].checked = !this.liveList[index].checked;
      this.liveList.forEach((item, posi) => {
        if (posi !== index) {
          const is = this.$moment(`${item.startDate} ${item.startTime}`);
          const ie = this.$moment(`${item.endDate} ${item.endTime}`);
          const cs = this.$moment(`${child.startDate} ${child.startTime}`);
          const ce = this.$moment(`${child.endDate} ${child.endTime}`);
          if ((ie > cs && is < ce) || (ce > is && cs < ie)) {
            this.liveList[posi].disabled = this.liveList[index].checked;
            this.$refs.groupCheckBox[posi].toggle(false);
          }
        }
      });
    },
    async sendCode() {
      // 获取验证码
      if (this.phoneRules.test(this.form.phone)) {
        this.phoneErrorMsg = "";
        this.timeOut();
        await this.$http.post(this.$baseURL.index.sendCode(), {
          phone: this.form.phone
        });
      } else {
        this.phoneErrorMsg = "请填写正确手机号";
        window.scrollTo(0, 0);
      }
    },
    timeOut() {
      // 验证码倒计时
      let count = 60;
      this.waitCode = true;
      this.interval = setInterval(() => {
        this.sendCodeText = count + "秒后重试";
        if (count === 0) {
          clearInterval(this.interval);
          this.sendCodeText = "获取验证码";
          this.waitCode = false;
        }
        count--;
      }, 1000);
    },
    idCardRules(val) {
      if (val.length === 0) {
        return true;
      } else {
        return this.$utils.identityCodeValid(val);
      }
    },
    onConfirm(date) {
      if (this.date.type === 1) {
        this.form[this.pickedItem.paramsName] = `${date.getFullYear()}-${(date.getMonth() + 1 + "").padStart(
          2,
          "0"
        )}-${(date.getDate() + "").padStart(2, "0")}`;
      } else if (this.date.type === 2) {
        this.form[this.pickedItem.paramsName] = date;
      } else {
        this.form[this.pickedItem.paramsName] = `${date.getFullYear()}-${(date.getMonth() + 1 + "").padStart(
          2,
          "0"
        )}-${(date.getDate() + "").padStart(2, "0")} ${(date.getHours() + "").padStart(2, "0")}:${(
          date.getMinutes() + ""
        ).padStart(2, "0")}`;
      }
      this.dateShow = false;
    },
    pickingDate(item, type, canEdit) {
      if (this.isEdit && canEdit === 0) {
        return;
      }
      this.pickedItem = item;
      this.date.type = type === "datePicker" ? 1 : type === "timePicker" ? 2 : 3;
      this.dateShow = true;
    },
    dateFormatter(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "时";
      }
      if (type === "minute") {
        return val + "分";
      }
    },
    timeFormatter(type, val) {
      if (type === "hour") {
        return val + "时";
      }
      if (type === "minute") {
        return val + "分";
      }
    },
    showGroup() {
      if (this.auditStatus) {
        return;
      }
      this.groupShow = true;
    },
    handleTipShow() {
      this.$emit("handleTipShow");
    },
    // 确认报名
    handleConfirm() {
      this.confirmSignup();
    },
    // 取消报名
    handleCancle() {},
    // 确认报名：提交
    confirmSignup() {
      if (this.sendLock) {
        return;
      }
      this.sendLock = true;
      this.$router.push('/user/' + this.$route.params.miceLink)
      this.sendTemplate();
      // const data = {
      //   ...this.applyInfo
      // };
      // this.$http
      //   .post(this.$baseURL.index.confirmSignup(), data, 1)
      //   .then(res => {
      //     this.sendLock = false;
      //     if (res.code === 200) {
      //       this.$emit("registerChange", res.data);
      //       if (!this.isEdit) {
      //         // 调用发送短信接口
      //         this.sendTemplate();
      //       }
      //     } else {
      //       Dialog.alert({
      //         title: "提示",
      //         message: res.message
      //       }).then(() => {
      //         // on close
      //       });
      //     }
      //   })
      //   .catch(() => {
      //     this.sendLock = false;
      //   });
    },
    // 省市区
    onAreaConfirm(values) {
      this.form.address = values
        .filter(item => !!item)
        .map(item => item.name)
        .join("/");
      this.showArea = false;
    }
  }
};
</script>

<style scoped lang="less">
@themeColor: var(--themeColor);
.group-wraper {
  // padding: 8px 12px;
  background: #fff;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  .tag {
    margin: 4px;
    border-radius: 4px;
    font-size: 10px;
  }
}

.photo-wraper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  transition: 0.5s;
  opacity: 1;
  z-index: 100;
}

.slide-enter,
.slide-leave-to {
  top: 100vh;
  opacity: 0.8;
}

.photo-box {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  height: 55px;
  width: 80px;
  margin-bottom: 10px;
  border-radius: 5px;
  overflow: hidden;
  background: rgb(169, 170, 191);
  color: #fff;
}

.head-img-wraper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .head-img {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 5px;
  }
  .loading {
    position: absolute;
  }
}

.check-style {
  margin-bottom: 10px;
}
/deep/.van-tag--primary {
  background-color: @themeColor;
}
/deep/ .van-overlay {
  background: rgba(0, 0, 0, 0.7);
}
.cellMask {
  color: #ccc;
  transition: 0.3s;
}
/deep/.van-radio__icon--checked .van-icon,
/deep/.van-checkbox__icon--checked .van-icon {
  background-color: @themeColor;
  border-color: @themeColor;
}

.report {
  min-height: 110px;
  line-height: 0.5;
}
/deep/.van-field__label {
  position: relative;
  color: #222;
  font-size: 13px;
  font-weight: bold;
  margin-right: 0.8rem;
  width: 76px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .tips {
    width: 100%;
    text-align: center;
    font-size: 10px;
    font-weight: normal;
    color: #666;
    position: absolute;
    bottom: 0;
    white-space: nowrap;
  }
}
/deep/.van-field__control {
  &::-webkit-input-placeholder {
    color: #999;
    font-size: 13px;
  }
}
.van-divider {
  margin: 0;
}
.input-field {
  &::after {
    position: absolute;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    content: "";
    pointer-events: none;
    right: 0.42667rem;
    bottom: 0;
    left: 0.42667rem;
    border-bottom: 0 solid #ebedf0 !important;
    -webkit-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}
/deep/.van-uploader__upload {
  background: rgb(169, 170, 191);
  height: 55px;
  border-radius: 5px;
}
/deep/.van-uploader__upload-icon {
  color: #fff;
  font-size: 18px;
}
.open {
  width: 100%;
  color: #102e9e;
  text-align: center;
  text-decoration: underline;
  margin-bottom: 20px;
}

.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    width: 80%;
    height: 80%;
    background-color: white;
    border-radius: 10px;
    text-align: center;
    img {
      width: 100%;
      height: 90%;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.btn-wrap {
  display: flex;
  justify-content: flex-end;
  padding: 15px 25px 0;
  span {
    font-size: 14px;
    text-decoration: underline;
  }
}

.code-box {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #2ca9e1;
  font-size: 12px;
  flex: 1;
  img {
    width: 70px;
  }
  div {
    line-height: 1.2;
  }
}

.submit-tip {
  padding: 20px;
}
// input::-webkit-input-placeholder{
//   color: #ccc;
// }

.save-tip {
  padding: 0 20px;
  font-size: 14px;
}

.des-wrapper {
  padding: 15px 10px;
  background: #fff;
  font-size: 14px;
  font-weight: bold;
  color: #646566;
}
</style>
