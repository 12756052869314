<template>
  <div class="sign-container" v-show="signOk" :style="{ '--themeColor': $store.state.themeInfo.themeColor }">
    <van-sticky>
      <head-bar type="primary" title="注册报名" @onClickLeft="onClickLeft" />
    </van-sticky>
    <div v-if="containsMiceLink">
      <!-- <img class="head-bg" src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202203141805152971834.gif" alt=""> -->
      <banner />
      <div calss="form-container" v-if="!registeSuccess">
        <sign-form @registerChange="handleChange" @handleTipShow="handleTipShow" />
      </div>
      <div class="center-vertical-o" v-if="registeSuccess">
      <!-- <div class="center-vertical-o" v-if="registeSuccess && answerResult"> -->
        <van-icon name="checked" style="font-size: 60px" class="icon" />
        <span>报名已提交!</span>
        <span>您可在峰会官网“我的”页面中查看审核结果。</span>
        <span>审核结果将于2023年8月30日前陆续公布</span>
        <!-- <span>审核结果将陆续公布</span> -->
        <!-- <span style="text-decoration: underline; color: #f00" @click="goToQuestion" v-if="!isAnswer">问卷调查</span> -->

        <div class="btn-wrap">
          <span :style="{ color: $store.state.themeInfo.themeColor }" @click="handleTipShow">报名须知</span>
        </div>
      </div>
      <float-menu />
      <transition name="slip">
        <div class="dialog-container" v-show="dialogShow">
          <div class="dialog-box">
            <div>
              <div class="dialog-title">温馨提示</div>
              <div class="confirm-wraper">
                <p>为了保证您能顺利参会，请您熟知以下报名须知：</p>
                <p>
                  <span><span class="index">1</span>、报名时间：</span>
                  2024年8月26日起
                </p>
                <p>
                  <span><span class="index">2</span>、报名入口：</span>
                  本届峰会报名入口为岳麓峰会公众号。
                </p>
                <p>
                  <span><span class="index">3</span>、参会地址：</span>
                  具体参会地点详见大会日程。
                </p>
                <p>
                  <span><span class="index">4</span>、报名信息：</span>
                  本次峰会均为实名制，报名时请如实填写本人真实有效信息，会议签到过程中如发现身份信息不符将无法参会。
                </p>
      

                <p>
                  <span><span class="index">5</span>、报名审核：</span>
                  峰会主办方将对报名人员进行审核，审核通过后方可参会，审核结果将于9月8日前陆续通知，敬请关注岳麓峰会微信公众号。s
                </p>

                <p>
                  <span><span class="index">6</span>、审核结果查询：</span>
                  您可在岳麓峰会公众号，点击峰会报名查询报名审核结果。温馨提醒：只能参加审核通过的场次，未审核通过的场次将无法参加！
                </p>
                <p>
                  <span><span class="index">7</span>、注意事项：</span>
                  同一身份证号和手机号不可重复报名。同一时段只能报名参加一个专场，提交前请仔细核对参加的场次信息。
                </p>
                <!-- <p>8、审核结果查询：您可在岳麓峰会公众号，点击峰会报名查询报名审核结果。您可参加审核通过的场次，未审核通过的场次将无法参会。</p> -->
                <!-- <p>9、注意事项：同一身份证号和手机号不可重复报名。同一时段只能报名参加一个专场，提交前请仔细核对参加的场次信息。</p> -->
                <!-- <p class="color-text" style="margin-top: 20px">联系我们：</p> -->
                <p class="color-text">主论坛及分论坛报名详见首页大会日程</p>
                <p class="color-text">技术电话：13957990591</p>
                <!-- <p class="color-text">技术支持：</p>
                <p class="color-text">报名咨询热线：0731-88992699</p> -->
                <p class="color-text">咨询时间：周一至周五9:00-17:00</p>
              </div>
              <div class="footer">
                <van-button
                  class="bth"
                  :style="{ color: timeOk ? $store.state.themeInfo.themeColor : '#999' }"
                  :disabled="!timeOk"
                  @click="confirm"
                  >{{ confirmText }}</van-button
                >
              </div>
            </div>
          </div>
        </div>
      </transition>
      <!-- <van-dialog
        class="dialog-box"
        title="温馨提示"
        v-model="dialogShow"
        :confirm-button-text="confirmText"
        :confirm-button-color="timeOk ? $store.state.themeInfo.themeColor : '#999'"
        @cancel="cancel"
        confirm-disabled
        @confirm="confirm"
      >
        <div class="confirm-wraper">
          <p>为了保证您能顺利参会，请您熟知以下报名须知：</p>
          <p>1、报名时间: 2023年 4月7日-4月12日</p>
          <p>2、报名入口：本届峰会报名入口为岳麓峰会公众号</p>
          <p>3、参会地址：具体参会地点详见大会日程</p>
          <p>4、报名信息：本次峰会均为实名制，报名时请如实填写本人真实有效信息，会议签到过程中如发现身份信息不符将无法参会。</p>
          <p>5、防疫问卷：根据卫健委防疫要求，提交报名之后须填写“峰会防疫问卷”，未填写问卷将影响报名审核结果。</p>
          <p>6、报名审核： 峰会主办方将对报名人员进行审核，审核通过后方可参会，审核结果将于4月14日前陆续通知，敬请关注岳麓峰会微信公众号。</p>
          <p>7、审核结果查询：您可在岳麓峰会公众号，点击峰会报名查询报名审核结果。您可参加审核通过的场次，未审核通过的场次将无法参会。</p>
          <p>8、注意事项：同一身份证号和手机号不可重复报名。同一时段只能报名参加一个专场，提交前请仔细核对参加的场次信息。</p>
          <p class="color-text" style="margin-top:20px">联系我们：</p>
          <p class="color-text">各会场报名咨询电话详见首页大会日程</p>
          <p class="color-text">技术支持：15364277087</p>
          <p class="color-text">咨询时间：周一至周五9:00-17:00</p>
        </div>
        <div class="footer">
          <van-button class="bth" :style="{ color: timeOk ? $store.state.themeInfo.themeColor : '#999' }" :disabled="!timeOk" @click="confirm">{{ confirmText }}</van-button>
        </div>
      </van-dialog> -->
    </div>
    <no-page v-else />
  </div>
</template>

<script>
import floatMenu from "@/components/floatMenu/index";
import headBar from "@/components/headBar/headBar";
import noPage from "@/components/noPage/noPage";
import signForm from "@/components/signForm/index2";
import banner from "@/components/banner/index";
export default {
  name: "signUp",
  components: { noPage, signForm, floatMenu, headBar, banner },
  data() {
    return {
      headBg: "",
      containsMiceLink: true,
      registeSuccess: false, // 注册成功
      miceLink: "",
      left: "calc(100% - 58px)",
      top: "600px",
      signOk: false,
      dialogShow: true,
      confirmText: "我已阅(6s)",
      timeOk: false,
      time: 6,
      isAnswer: true, // 是否回答问卷
      answerResult: false // 问卷是否查询成功
    };
  },
  created() {
    const user = this.$utils.getCookie("user");
    // const user = {
    //   openid: 'ogQs3w39cHZQmueTFbwcLD5lgmHs'
    // }
    if (!user || !user.openid) {
      if (!this.$route.query.code) {
        // window.location.href = this.$baseURL.weixin.getWxCode(window.location.href.split('#')[0])
        this.getWxCode();
        this.signOk = true;
        return;
      } else {
        this.signOk = true;
        this.code = this.$route.query.code;
        this.$http.postParams(this.$baseURL.weixin.userSign(), { code: this.code }, 1).then(res => {
          if (res.code === 200) {
            this.$utils.setCookie("user", JSON.stringify(res.data ? res.data : {}));
            this.$store.commit("userInfo/SET_USER", res.data);
            this.init()
          }
        });
      }
    } else {
      this.signOk = true;
      this.$store.commit("userInfo/SET_USER", user);
      this.init()
     
    }
  },
  mounted() {
    
  },
  methods: {
    init() {
      if (this.$route.params.miceLink) {
        this.miceLink = this.$route.params.miceLink;
        this.containsMiceLink = true;
        this.getBg();
        // this.dialogShow = true
        //this.agreeTimeOut()
        this.getAuditStatus();
        // this.initFloatMenu()
        this.isAnswerExist()
      } else {
        this.containsMiceLink = false;
      }
    },
    // 获取是否报名
    async getAuditStatus() {
      const data = {
        miceLink: this.miceLink,
        openId: this.$store.state.userInfo.openid
      };
      let result = await this.$http.postParams(this.$baseURL.index.getAuditStatus(), data, 1);
      if (result.data) {
        this.registeSuccess = true;
        this.time = 0;
        this.isAnswerExist();
        this.$router.push(`/user/${this.$route.params.miceLink}`);
      } else {
        this.dialogShow = true;
      }
      this.agreeTimeOut();
    },
    async getBg() {
      let result = await this.$http.get(this.$baseURL.index.getMiceInfo() + "?miceLink=" + this.miceLink);
      this.headBg = result.posters;
    },
    agreeTimeOut() {
      const interval = setInterval(() => {
        if (this.time <= 0) {
          this.confirmText = "我已阅";
          clearInterval(interval);
          this.timeOk = true;
          return;
        }
        this.confirmText = `我已阅(${this.time}s)`;
        this.time--;
      }, 1000);
    },
    goToIndex() {
      this.$router.push(`/index/${this.$store.state.miceInfo.miceLink}`);
    },
    handleChange() {
      this.registeSuccess = true;
      // window.location.href = 'https://www.wjx.cn/vj/QBlApAm.aspx'
      // this.$router.push(`/questionnaire/default/050a6644946abe7a492c67587fb6357c/${this.$route.params.miceLink}`);
    },
    goToQuestion() {
      // window.location.href = 'https://www.wjx.cn/vj/QBlApAm.aspx'
      // this.$router.push(`/questionnaire/default/050a6644946abe7a492c67587fb6357c/${this.$route.params.miceLink}`);
    },
    initFloatMenu() {
      this.$nextTick(() => {
        const menu = document.querySelector(".float-menu");
        const mh = parseFloat(getComputedStyle(menu).height);
        const mw = parseFloat(getComputedStyle(menu).width);
        const screenWidth = parseFloat(getComputedStyle(document.documentElement).width);
        const screenHeight = parseFloat(window.screen.availHeight);
        this.left = `calc(100% - ${mw}px)`;
        menu.addEventListener(
          "touchmove",
          e => {
            e.preventDefault();
            menu.style.left = e.touches[0].clientX - mw / 2 + "px";
            menu.style.top = e.touches[0].clientY - mh / 2 + "px";
            menu.style.transition = "0s";
          },
          { passive: false }
        );
        menu.addEventListener(
          "touchstart",
          () => {
            menu.classList.remove("fl-right");
            menu.classList.remove("fl-left");
          },
          { passive: false }
        );
        menu.addEventListener(
          "touchend",
          () => {
            menu.style.transition = ".3s";
            if (screenWidth - parseFloat(getComputedStyle(menu).left) - mw / 2 > screenWidth / 2) {
              menu.style.left = "0px";
              menu.classList.add("fl-left");
            } else {
              menu.style.left = `calc(100% - ${mw}px)`;
              menu.classList.add("fl-right");
            }
            if (
              parseFloat(getComputedStyle(menu).top) < 0 ||
              parseFloat(getComputedStyle(menu).top) + mh > screenHeight
            ) {
              menu.style.top = parseFloat(getComputedStyle(menu).top) < 0 ? "0px" : screenHeight - mh + "px";
            }
          },
          { passive: false }
        );
      });
    },
    getWxCode() {
      window.location.href = this.$baseURL.weixin.getWxCode(window.location.href.split("#")[0]);
    },
    cancel() {
      this.$router.push("/index/" + this.$store.state.miceInfo.miceLink);
    },
    confirm() {
      // console.log('confirm')
      if (!this.timeOk) {
        return;
      }
      this.dialogShow = false;
    },
    handleTipShow() {
      this.dialogShow = true;
    },
    onClickLeft() {
      this.$router.push("/index/" + this.$store.state.miceInfo.miceLink);
    },
    // 是否回答问题
    async isAnswerExist() {
      this.answerResult = false;
      const id = "050a6644946abe7a492c67587fb6357c"; // 问卷id
      let result = await this.$http.get(
        this.$baseURL.questionnaire.answerIsExist() +
          `?questionnaireId=${id}&openId=${this.$store.state.userInfo.openid}`,
        1
      );
      if (result.code === 200) {
        this.isAnswer = result.data;
        if (result.data) {
          this.answerResult = true;
        } else {
          this.goToQuestion();
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
@themeColor: var(--themeColor);
.dialog-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 2015;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  // padding: 50px 0;
  .dialog-box {
    width: 93%;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    // margin-top: 100px;
    .dialog-title {
      width: 100%;
      padding: 25px 0 5px;
      text-align: center;
      font-size: 15px;
      color: #333;
    }
  }
}
.sign-container {
  height: 100vh;
  overflow-y: auto;
  background: rgb(244, 244, 252);
}
.head-bg {
  width: 100%;
}
.center-vertical-o {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  padding: 15px 0;
  color: @themeColor;
  // height: 150px;
  span {
    font-size: 15px;
    padding: 5px 20px;
    text-align: center;
  }
  .icon {
    animation: jump 0.3s;
    font-size: 60px;
  }
  @keyframes jump {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
  }
}
.check-style {
  margin-bottom: 10px;
}
.dialog-box {
  width: 93%;
  /deep/.van-dialog__footer {
    display: none;
  }
}
.footer {
  width: 100%;
  // padding: 5px 0;
  margin-top: 10px;
  /deep/.van-button--default {
    border: none;
    width: 100%;
    // border-top: 1px solid @themeColor;
    background: #f4f5ff;
  }
}
.confirm-wraper {
  padding: 0 13px 0;
  font-size: 12px;
  // max-height: 300px;
  // overflow-y: auto;
  .color-text {
    color: @themeColor;
  }
  .index {
    width: 9px;
    display: inline-block;
  }
  p {
    margin: 0;
    color: #666;
    &:nth-of-type(1) {
      // text-indent: 15px;
      font-size: 14px;
      margin: 15px 0 10px;
      color: @themeColor;
    }
    span {
     
    }
    & {
      margin: 2px 0;
    }
  }
}

.slip-enter,
.slip-leave-to {
  opacity: 0;
  // transform: scale(.5);
  // top: 100%;
}
.slip-enter-active,
.slip-leave-active {
  transition: 0.3s;
}

.btn-wrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 15px 25px 0;
  span {
    font-size: 14px;
    text-decoration: underline;
  }
}
</style>
